import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Balagha`}</strong>{` Or `}<strong parentName="p" {...{
        "className": "firstword"
      }}>{`البلاغة`}</strong>{` is a الكلام or Speech that affects us, moves us, etc.`}</p>
    <br />
    <p>{`To understand it better, let's take a step back and look into how we communicate,`}</p>
    <ul>
      <li parentName="ul">{`We have thoughts/feelings/emotions inside us, we call it المعنى في النفس`}</li>
      <li parentName="ul">{`To express those thoughts/feelings/emotions, we use language, i.e. اللغة. So اللغة is a medium for الاتصال (communication)`}</li>
    </ul>
    <br />
    <p>{`This entire process is known as البيان, i.e., the ability to express meaning in the اللغة `}</p>
    <br />
    <p>{`We may have a turmoil going on inside ourselves but to express that emotion we may say, "I'm happy" Or "I'm Sad" etc. From this, we establish that اللغة is weaker than المعنى في النفس. `}</p>
    <br />
    <p>{`But if we are able to make اللغة stronger to carry more of المعنى في النفس. Then that اللغة is الكلام العالي.`}</p>
    <br />
    <p>{`That is where the study of علم البلاغة fits in. We try to make our اللغة more effective in conveying the المعنى في النفس.`}</p>
    <br />
    <p>{`Historically, علم البلاغة started to learn إعجاز القرآن الكريم. Allah SWT has challenged the Arabs to compete in البيان of the Quran in there own style.`}</p>
    <br />
    <p>{`Arabs at that time of the revelation of the Quran were the masters of the Arabic language. Some of the best poets for example امرؤ القيس, زهير, النابغة, لبيد  ,كعب بن زهير lived that time.`}</p>
    <br />
    <p>{`When Arabs heard the Quran, they understood that this is not the work of a human.`}</p>
    <br />
    <p>{`Imagine a situation in which a person heard a page of Quran or couple of Ayats, and he is ready to leave his previous beliefs, his city, etc. That was the effect of Quran on Arabs. For example, Umar ibn Khattab (RA) just heard few Ayats of Surah Taha and became Muslim.`}</p>
    <br />
    <p>{`So to answer,`}</p>
    <h2>{`Why we study Balagha?`}</h2>
    <p>{`We say we study Balagha so that,`}</p>
    <ul>
      <li parentName="ul">{`We know the الكلام البليغ from الكلام الضعيف`}</li>
      <li parentName="ul">{`We can use البلاغة to make our speech beautiful`}</li>
    </ul>
    <br />
    <p>{`Before moving any further, let's understand the terms الفصاحة and البلاغة and the difference between them.`}</p>
    <h2>{`الفصاحة`}</h2>
    <p>{`الفصاحة comes from the word أفصَح  يفصِح  أفصح  الإفصاح meaning أبان وأظهر`}</p>
    <br />
    <p>{`الفصاحة is studied under three heads`}</p>
    <ul>
      <li parentName="ul">{`فصاحة الكلمة (eloquence of word)`}</li>
      <li parentName="ul">{`فصاحة الكلام (eloquence of speech)`}</li>
      <li parentName="ul">{`فصاحة المتكلم (eloquence of speaker)`}</li>
    </ul>
    <br />
    <p>{`Let's begin with`}</p>
    <h3><strong parentName="h3">{`فصاحة الكلمة`}</strong></h3>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Rule 1`}</strong>{` `}<strong parentName="p">{`عدم تنافر الحروف`}</strong><br parentName="p"></br>{`
`}{`There should be no تنافر in الحروف. By تنافر, we mean mutual aversion, discord between the letters. For, e.g. word, مستشزرات is different to pronounce.`}</p>
    <br />
We should avoid تنافر because it makes the word heavy on the tongue to pronounce.
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Rule 2`}</strong>{` `}<strong parentName="p">{`مخالفة القياس`}</strong><br parentName="p"></br>{`
`}{`If we come with a word that doesn't match the rules, then it is مخالفة القياس. For e.g., Plural of بوق is أبواق, so if we use بوقات in place of أبواق then it is مخالفة القياس`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Rule 3`}</strong>{` `}<strong parentName="p">{`الغرابة`}</strong><br parentName="p"></br>{`
`}{`الغرابة mean to use strange words in a sentence. For e.g. using تكأكأ (to gather) `}</p>
    <h3><strong parentName="h3">{`فصاحة الكلام`}</strong></h3>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Rule 1`}</strong>{` `}<strong parentName="p">{`عدم تنافر الكلمات`}</strong><br parentName="p"></br>{`
`}{`There should be no تنافر in الكلمات. By تنافر, we mean we do not put words together in الكلمات which makes it hard on the tongue.  `}</p>
    <br />
    <p>{`For e.g., a tongue twister like  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`قبر حرب بمكان قفر    وليس قرب قبر حرب قبر`}</code></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Rule 2`}</strong>{` `}<strong parentName="p">{`ضعف التأليف Or مخالفة النحو المشهور`}</strong><br parentName="p"></br>{`
`}{`If we make a sentence against a rule in Nahw.`}<br parentName="p"></br>{`
`}{`In ضعف التأليف, our sentence is correct, but we have contradicted an important rule in Nahw.`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Rule 3`}</strong>{` `}<strong parentName="p">{`التعقيد`}</strong><br parentName="p"></br>{`
`}{`After reading a text, If we are not clear what the speaker wanted, i.e. الكلام صعب الفهم.`}</p>
    <h3><strong parentName="h3">{`فصاحة المتكلم`}</strong></h3>
    <p>{`It is the ability of the speaker to be able to express what he wants with الكلام فصاحة in any field or area.`}</p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`قدرة المتكلم على التعبير عما يريد بكلام فصيح`}</p>
    </blockquote>
    <h2>{`البلاغة`}</h2>
    <p>{`البلاغة comes from the word بلَغ  يبْلُغ  البُلُوغ meaning وصل (to reach).`}<br parentName="p"></br>{`
`}{`For e.g., بلغت مكة == وصلت إلى مكة`}<br parentName="p"></br>{`
`}{`It can be understood under two heads`}</p>
    <ul>
      <li parentName="ul">{`بلاغة الكلام`}</li>
      <li parentName="ul">{`بلاغة المتكلم`}</li>
    </ul>
    <br />
    <p>{`PS: We don't have the concept of بلاغة الكلمة because when we can't reach/affect someone's heart with one word. We need a sentence for that`}</p>
    <br />
    <p>{`Let's begin with`}</p>
    <h3><strong parentName="h3">{`بلاغة الكلام`}</strong></h3>
    <p>{`بلاغة الكلام implies using the right style in the right place. Arabs say, "لكلِ مَقَام مَقَال" meaning for every situation there is a قول. `}</p>
    <blockquote>
      <p parentName="blockquote">{`أن يكون الكلام مناسباً لما تريد أن تقوله مع الفصاحة`}</p>
    </blockquote>
    <br />
    <p>{`For e.g., in the Quran the same story is being told in different places with different styles, different words, the different type of Tawkeed, etc.`}</p>
    <h3><strong parentName="h3">{`بلاغة المتكلم`}</strong></h3>
    <p>{`It is the ability of the speaker to be able to express what he wants with الكلام بليغ in any field or area.`}</p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`قدرة المتكلم على التعبير عما يريد بكلام بليغ`}</p>
    </blockquote>
    <br />
    <p>{`Finally, البلاغة is studied under 3 main heads.`}</p>
    <h2>{`أقسام علم البلاغة`}</h2>
    <ul>
      <li parentName="ul">{`(علم المعاني(علم معاني النحو`}</li>
      <li parentName="ul">{`علم البيان`}</li>
      <li parentName="ul">{`علم البديع`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "351px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.30061349693251%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJklEQVQ4y51UaW/aQBDl//+DKqQJJP3SpI1atekRmoqUOw6qUlWCGgNpoAFskoDxsd7jdXetGgzkS1d6mtk5nsczY2eEEFDnn/yfs5qbWTUwLkCZAgelHBFlUjItY50vdRUjwfmyIIXMkoyjMyL4eRuifUdhjgUsG+hMhATQm0LaOCwH2v5rxHSsNQoVHRRNipBEDOYo0skv35eQf1WQsoyj0zJOi9fIHX3GRbOPNwUDh6+/4PhDBV35kPYwBFbalhCq12j/IWjdcRTqHZzVTJwUrvCu+EPiGidnBj5V2nh7/h3HH+soNm/QGgm0hnGFG4RK2rMIgymBvQAcBS/G0KGYzICJC0zl/T6I9YFD4MyjdA+3T1jdeSw5gz/3QPxA69omeFJVnI/knqowBUUpJ0jCSIPJiZIg0jaxFr8xlJQzCYImULoi5JJQ60G0vYDVtdkgVUMiFDRisa4IOY/XS+1i+DRpZtvm+4swqUSdwCOghCV39TDPDTa/GNVD6rqYG5eYGQ1M61XcV8uYWz3tjBwHrlGHXS7DqVakrwT6+KCTXdPCo4wfV0p4aNRkbg2chMhwGdDI7eNiN4vaQQ7V51l4ZlsnhYMBjPw+vu48Q+PFAS73dkFtW/vGzSt8k/ZidgeNwzzquT2IIJCEhMDrWhrhTR9hr5tUQRcu3I4Jv9+Lff0umO/HbbAnWEgfHdwikH5y+xuC0nQPxcp2re+meOLvsr7BfwFgN3oK1+Gd8AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "3 Divisions of Balagha",
          "title": "3 Divisions of Balagha",
          "src": "/static/4fb94714c54dbe9a66fa5d836c1c46db/7c2a6/3-divisions-of-balagha.png",
          "srcSet": ["/static/4fb94714c54dbe9a66fa5d836c1c46db/222b7/3-divisions-of-balagha.png 163w", "/static/4fb94714c54dbe9a66fa5d836c1c46db/ff46a/3-divisions-of-balagha.png 325w", "/static/4fb94714c54dbe9a66fa5d836c1c46db/7c2a6/3-divisions-of-balagha.png 351w"],
          "sizes": "(max-width: 351px) 100vw, 351px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`We will try to cover each topic individually in a separate post. Till then`}<br parentName="p"></br>{`
`}{`Happy Learning !!!`}</p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      